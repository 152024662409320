<template>
    <div>
      <HeaderNavigation :info="$store.state.infos" :checkValue="5" />
        <div class="top_pic"><img :src="banner" alt=""></div>

        <div class="title_box_txt">
            <div class="title1">品牌荣耀</div>
            <div class="title2">Brand glory</div>
        </div>
        <div :class="glory.length>4?'three_kuai after_box after_box1':'three_kuai'">
            <div v-for="(item, index) of glory" :key="index" class="item_three_kuai">
                <div class="certificate"><img :src="item.gloryImg" alt=""></div>
                <div class="certificate_text">{{item.title}}</div>
            </div>
        </div>

      <BottomNavigation :info="$store.state.infos" />
      <RightMenu :info="$store.state.infos" @openpop="openpop" />
      <JoinBulletFrame v-if="JoinModal" @closepop="closepop" />
    </div>
</template>

<script>
    import {cateBanner,glory_xq} from '@/api/index.js'
    import HeaderNavigation from "@/components/header_navigation.vue";
    import BottomNavigation from "@/components/bottom_navigation.vue";
    import RightMenu from "@/components/right-menu.vue";
    import JoinBulletFrame from "@/components/joinBulletFrame.vue";
    export default {
        components: {
            HeaderNavigation,
            BottomNavigation,
            RightMenu,
            JoinBulletFrame
        },
        data(){
            return{
                classValue:1,
                titleList:[],
                banner:'',
                shopList:[],
                glory:[],
                JoinModal:false,
            }
        },
        created() {
            window.scroll(0, 0)
            this.glory_xq()
            this.cateBanner()
        },
        methods:{
            cateBanner(){
                cateBanner({id:8}).then(res=>{
                    this.banner = res.data
                })
            },
            glory_xq(){
                glory_xq().then(res=>{
                    this.glory = res.data
                })
            },
            openpop(e){
                this.JoinModal = e
            },
            closepop(e){
                this.JoinModal = e
            },
        }
    }
</script>

<style scoped>
.top_pic{
    width: 100%;
    height: 600px;
}
.top_pic img{
    width: 100%;
    height: 100%;
  object-fit: fill;
}
.shop_da_box{
  width: 901px;
  margin: 0 auto;
  padding: 50px 0;
}
.title1 {
  font-size: 16px;
  font-weight: 600;
}
.title2 {
  color: #999;
  font-size: 12px;
  margin-top: 10px;
}
.shop_box{
  width: 100%;
  margin-top: 30px;
}
.nav_title_box{
  display: flex;
  align-items: center;
  width: 100%;
  overflow: auto;
}
.item_class_box{
  width: 100px;
  border-radius: 12px;
  line-height: 40px;
  cursor: pointer;
  text-align: center;
}
.yes_item_class_box{
  width: 100px;
  border-radius: 12px;
  line-height: 40px;
  cursor: pointer;
  text-align: center;
  background: #A91800;
  color: #FFF;
}
.nav_content_box{
  width: 100%;
  margin-top: 30px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}
.nav_content_box::after{
  width: 240px;
  height: 190px;
  display: block;
  content: ' ';
}
/* .item_content_box{
  width: 33.33%;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
} */
.nei_item_content_box{
  width: 240px;
  margin-bottom: 20px;
  background: #F7F7F7;
}
.item_content_box_pic img{
  width: 100%;
  object-fit: cover;
}
.item_content_box_title{
  text-align: center;
  margin: 2px 0;
}
.title_box_txt {
  padding: 50px 0;
  text-align: center;
}
.three_kuai{
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.after_box::after{
  width: 260px;
  display: block;
  content: ' ';
}
.after_box1::after{
  width: 260px;
  display: block;
  content: ' ';
}
/* .three_kuai::after{
  width: 260px;
  display: block;
  content: ' ';
} */
.item_three_kuai {
  /* width: 25%; */
  margin-bottom: 30px;
}
.certificate{
  width: 260px;
  display: flex;
  background: #F9F9F9;
  padding: 30px 0;
  align-items: center;
  justify-content: center;
}
.certificate img{
  width: 200px;
  height: 160px;
  object-fit: cover;
}
.certificate_text{
  text-align: center;
  padding: 10px 0;
}
.four_box{
  width: 100%;
  padding: 0 0 60px 0;
  background: #F3F3F3;
}
.nei_four_box{
  width: 80%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>